import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import UniversalLink from "../components/UniversalLink"

export const IndexPageTemplate = ({
  image,
  title,
  hours,
  location,
  locationUrl,
  email,
  phone,
  blackboard,
  specials,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div className="hero-banner">
        <h1>{title}</h1>
        <h3>{hours}</h3>
        <h3>
          <UniversalLink
              to={locationUrl}
              className=""
              target="_blank"
              rel="noopener noreferrer"
            >
              {location}
          </UniversalLink>
        </h3>
        <h3>
          <a
            href={"mailto:"+email}
            target="_blank"
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </h3>
        <h3>
          <a
            href={"tel:"+phone}
            target="_blank"
            rel="noopener noreferrer"
          >
            {phone}
          </a>
        </h3>
      </div>
    </div>
    <section className="section">
      <div className="container centered">
        <div className="closed">
          <h2>{blackboard.addMessage}</h2>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="blackboard centered">
          <h1>Blackboard Specials</h1>
          <h2>{blackboard.date}</h2>
          <h3>{blackboard.message}</h3>
          <h1>Specials of the Week</h1>
          <h4>Soup</h4>
          <h5>{specials.soup}</h5>
          <h4>Salad</h4>
          <h5>{specials.salad}</h5>
          <h4>Muffin</h4>
          <h5>{specials.muffin}</h5>
          <h4>Congealed Salad</h4>
          <h5>{specials.congealedSalad}</h5>
          <h4>Quiche of the Day</h4>
          <h5>Monday: {specials.quicheMon}</h5>
          <h5>Tuesday: {specials.quicheTue}</h5>
          <h5>Wednesday: {specials.quicheWed}</h5>
          <h5>Thursday: {specials.quicheThu}</h5>
          <h5>Friday: {specials.quicheFri}</h5>
          <h4>Entree of the Week</h4>
          <h5>{specials.entree}</h5>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container centered">
        <UniversalLink
            to="/menu"
            className="button special"
          >
            Menu
        </UniversalLink>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  hours: PropTypes.string,
  location: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  blackboard: PropTypes.object,
  specials: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        hours={frontmatter.hours}
        location={frontmatter.location}
        locationUrl={frontmatter.locationUrl}
        email={frontmatter.email}
        phone={frontmatter.phone}
        blackboard={frontmatter.blackboard}
        specials={frontmatter.specials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        hours
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        location
        locationUrl
        email
        phone
        blackboard {
          date
          message
          addMessage
        }
        specials {
          soup
          salad
          muffin
          congealedSalad
          quicheMon
          quicheTue
          quicheWed
          quicheThu
          quicheFri
          entree
        }
      }
    }
  }
`
